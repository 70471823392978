import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Address } from '../models';
import { Crud, CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/addresses`,
  entity: 'Address',
  hasIdPathUpdate: true,
})
export class AddressService extends CrudService<Address> {
  constructor(http: HttpClient) {
    super(http);
  }
}
