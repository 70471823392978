import {
  Component,
  Input,
  Injector,
  AfterViewInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FilterParams,
  PageableCollection,
  ForeignVisitor,
} from '@app/shared/models';
import { Observable } from 'rxjs';
import { FvService } from '@app/shared/services/';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { FloatLabelType } from '@angular/material/form-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-select-fv',
  templateUrl: './select-fv.component.html',
  styleUrls: ['./select-fv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFvComponent
  extends BaseMatSelectComponent<ForeignVisitor>
  implements AfterViewInit
{
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() filterLabel: string = 'Search';

  @Input() multiple: boolean;
  @Input() label: string = 'Select a Foreign National';
  @Input() farId: string = '';
  @Input() restrictCurrentOrganization: boolean = true;
  @Input() organizationId: string = '';

  @Output() fvIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set fvId(_orgId: string) {
    this.loadOrgId(_orgId);
  }

  get fvId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    currentOrganization: this.restrictCurrentOrganization,
    sort: {
      active: 'surname',
      direction: 'asc',
    },
  });

  constructor(injector: Injector, private fvService: FvService) {
    super(injector);
  }

  loadOrgId(fvId: string) {
    if ((!fvId && !this.value) || fvId === this.value?.id) return;

    if (!fvId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: fvId };
    this.fvService
      .get(fvId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  ngAfterViewInit(): void {
    this.baseFilterParams['excludeFarId'] = this.farId;
    this.baseFilterParams['currentOrganization'] =
      this.restrictCurrentOrganization;

    if (this.organizationId)
      this.baseFilterParams['organizationId'] = this.organizationId;

    super.ngOnInit();
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.fvIdChange.emit(this.fvId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<ForeignVisitor>> {
    return this.fvService.find(params);
  }

  getSelectedText(values: any) {
    if (values?.length) {
      const singleItem = `${values[0].surname}, ${values[0].givenName}`;
      const multipleItems = `${values.length} Options Selected`;
      return values.length > 1 ? multipleItems : singleItem;
    }

    return `${values?.surname}, ${values?.givenName}`;
  }
}
