import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FAR, ForeignVisitor } from '@app/shared/models';
import { FarService } from '@app/shared/services';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-fvs-dialog',
  templateUrl: './add-fvs-dialog.component.html',
  styleUrls: ['./add-fvs-dialog.component.scss'],
})
export class AddFvsDialogComponent implements OnInit {
  fvsToAssociate: ForeignVisitor[] = [];
  farId: string = '';
  farOrganizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;

  constructor(
    private farService: FarService,
    @Inject(MAT_DIALOG_DATA) private far: FAR,
    private dialog: MatDialogRef<AddFvsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ok = false;
    this.farId = this.far.id;
    if (this.far && this.far.ownerOrganizationId)
      this.farOrganizationId = this.far.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;
    this.farService
      .addFvToFar(this.far.id, this.fvsToAssociate)
      .pipe(take(1))
      .subscribe((far) => {
        this.onClose();
      });
  }
  onClose() {
    this.dialog.close();
  }
  onRemove(fv: ForeignVisitor) {
    const filtered = this.fvsToAssociate.filter((x) => {
      return fv.id != x.id;
    });
    this.fvsToAssociate = filtered;
  }
}
