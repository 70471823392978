import { Component, Input } from '@angular/core';
import { Colors } from '@app/shared/components';
import { ForeignVisitor, Passport } from '@app/shared/models';

@Component({
  selector: 'app-fn-profile',
  templateUrl: './fn-profile.component.html',
  styleUrls: ['./fn-profile.component.scss'],
})
export class FnProfileComponent {
  @Input() fn: ForeignVisitor;
  @Input() showPills: boolean = true;

  get passports(): Passport[] {
    return this.fn.passports ?? [];
  }

  get passport(): string | null {
    if (!this.fn.passports?.length) return null;
    const latestPassportNumber = this.fn.passports[0].number;
    const latestPassportCountry = this.fn.passports[0].issuingCountryCode;
    return `${latestPassportNumber} | ${latestPassportCountry}`;
  }

  get dateOfBirth(): string | null {
    if (!this.fn.dateOfBirth) return null;
    return `${this.fn.dateOfBirth}`;
  }

  get passportColor(): Colors {
    return this.passport ? 'primary' : 'danger';
  }
  get dobColor(): Colors {
    return this.dateOfBirth ? 'primary' : 'danger';
  }
  get dobTooltip() {
    if (this.dateOfBirth) return '';
    return 'Date of Birth is required to screen a Foreign National.';
  }
  get passportTooltip() {
    if (this.passport) return '';
    return 'Passport number and passport country are required to screen a Foreign National.';
  }
}
