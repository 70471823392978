<mat-toolbar
  class="row px-0 justify-content-between"
  [class]="hideBorderTop ? 'paginator-recolor' : ''"
  [ngClass]="showWarningHeader ? 'paginator-recolor-warning' : ''"
>
  <div class="col pl-0 d-flex align-items-center" *ngIf="title">
    <mat-icon *ngIf="icon" class="mr-1 ml-2" [svgIcon]="icon"></mat-icon
    >{{ title }}
  </div>
  <div class="col pl-0" [hidden]="!enableQuickFilters">
    <button
      mat-button
      (click)="updateFilterParams('result', 'RED')"
      *ngIf="metrics.redResultCount > 0"
    >
      Derogatory ({{ metrics.redResultCount }})
    </button>
    <button
      mat-button
      (click)="updateFilterParams('status', 'Completed')"
      *ngIf="metrics.completedStatusCount > 0"
    >
      Screened ({{ metrics.completedStatusCount }})
    </button>
    <button
      *ngIf="!screeningReview && metrics.ineligibleScreenings > 0"
      mat-button
      (click)="updateFilterParams('screeningEligible', 'false')"
    >
      Ineligible ({{ metrics.ineligibleScreenings }})
    </button>
    <button
      mat-button
      (click)="updateFilterParams('status', 'In Process')"
      *ngIf="metrics.inProcessStatusCount > 0"
    >
      In Process ({{ metrics.inProcessStatusCount }})
    </button>
    <button
      mat-button
      (click)="updateFilterParams('status', 'Error')"
      *ngIf="metrics.errorStatusCount > 0"
    >
      Errors ({{ metrics.errorStatusCount }})
    </button>
    <button
      mat-button
      (click)="updateFilterParams('result', 'MACHINE_RED')"
      *ngIf="metrics.machineRedResultCount > 0"
    >
      Machine Red ({{ metrics.machineRedResultCount }})
    </button>
  </div>
  <div class="col pr-0">
    <mat-paginator
      [hidden]="!showPaginator"
      class="paginator"
      [length]="totalElements"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      #MatPaginator
      aria-label="Select page"
      [showFirstLastButtons]="showFirstLastButtons"
      [hidePageSize]="hidePageSize"
    >
    </mat-paginator>
  </div>
</mat-toolbar>
