<form #locationSelectForm="ngForm">
  <mat-form-field appearance="outline">
    <mat-label>Locations</mat-label>
    <mat-select
      [required]="required"
      #matSelect
      placeholder="Location"
      name="locationSelect"
      [(ngModel)]="value"
      (selectionChange)="emitChangeEvent()"
      (openedChange)="monitorScroll($event)"
    >
      <mat-select-trigger *ngIf="value && value.name; else noName">
        {{ value.name }}
      </mat-select-trigger>
      <ng-template #noName>
        <ng-container *ngIf="value && value.address">
          {{ value.address | address }}
        </ng-container>
      </ng-template>
      <mat-option>
        <ngx-mat-select-search
          [searching]="searching"
          name="locationFilter"
          [placeholderLabel]="'Search Locations'"
          [noEntriesFoundLabel]="'No Locations Found'"
          [formControl]="searchCtrl"
          [disableScrollToActiveOnOptionsChanged]="true"
          [clearSearchInput]="false"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngIf="showCreate"
        id="new-location-button"
        matTooltip="New Location"
        [appLocationEdit]
        (appLocationEdited)="onCreateLocation($event)"
      >
        <mat-list style="line-height: normal">
          <mat-list-item>
            <mat-icon
              matListItemIcon
              color="primary"
              svgIcon="plus-circle-outline"
            ></mat-icon>
            <span matListItemLine> Add New Location</span>
          </mat-list-item>
        </mat-list>
      </mat-option>
      <mat-option
        class="pb-2"
        *ngFor="
          let location of filteredValues | async;
          index as i;
          trackBy: trackBy
        "
        [value]="location"
      >
        <mat-list style="line-height: normal" class="p-0">
          <mat-list-item>
            <mat-icon
              color="primary"
              matListItemIcon
              svgIcon="office-building-marker"
            >
            </mat-icon>
            <h2 matListItemTitle [appHighlight]="searchCtrl.value">
              {{ location.name }}
            </h2>
            <h5
              *ngIf="location.address"
              matListItemLine
              [appHighlight]="searchCtrl.value"
            >
              {{ location.address | address: "street" }},
              {{ location.address | address: "cityState" }}
            </h5>
          </mat-list-item>
        </mat-list>
      </mat-option>
      <mat-option class="selected-options" *ngIf="value" [value]="value">
        <mat-icon
          class="flex-shrink-0"
          style="position: relative; top: 5px"
          color="primary"
          mat-list-icon
          svgIcon="office-building-marker"
        ></mat-icon>
        <span class="pl-1">{{ value.name }}</span>
      </mat-option>
    </mat-select>
    <mat-error>{{ errorMessage }}</mat-error>
    <button
      mat-icon-button
      matSuffix
      *ngIf="value"
      matTooltip="Clear"
      [stopPropagation]
      (click)="clearValue($event)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</form>
