import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import { FarPurposeTypes, Role } from '@shared/models';

export const FarAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Event Title',
    name: 'eventTitle',
    maxLength: 256,
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Purpose Type',
    name: 'purposeType',
    options: FarPurposeTypes,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Sponsor Name',
    name: 'sponsorName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Location Name',
    name: 'locationName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Foreign National Name',
    name: 'foreignVisitorName',
    maxLength: 256,
    helpText: 'Free Text Search',
  },
  {
    type: FilterConfigType.FN,
    label: 'Foreign National',
    name: 'fvId',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Location Id',
    name: 'locationId',
    formHidden: true,
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Deletion Date',
    name: 'deletionDate',
    ifConfig: {
      key: 'dataDeletionFiltersEnabled',
      defaultVal: true,
    },
    min: new Date(),
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
    ],
  },
];
