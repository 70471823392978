<div class="my-2">
  <mat-table
    [dataSource]="locations"
    aria-label="'locations-table"
    #locationTable
    multiTemplateDataRows
    matSort
    [matSortActive]="
      filterParams.sort && filterParams.sort.active
        ? filterParams.sort.active
        : ''
    "
    [matSortDirection]="
      filterParams.sort && filterParams.sort.direction
        ? filterParams.sort.direction
        : ''
    "
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="OrgLocationSortField.NAME">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.NAME]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.NAME] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.NAME]"
      >
        <div [matTooltip]="location.name" style="line-break: anywhere">
          {{ location.name | default }}
        </div>
        <span>
          <mat-icon
            matTooltip="Read Only Record"
            class="ml-2"
            style="opacity: 0.7"
            color="primary"
            *ngIf="!location.isEditable"
            svgIcon="pencil-off-outline"
          >
          </mat-icon>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.ADDRESS">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.ADDRESS]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.ADDRESS] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.ADDRESS]"
      >
        {{ location.address | address: "street" | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.CITY_STATE_PROVINCE">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header="{{ OrgLocationSortField.CITY }}"
        [hidden]="
          !locationColumnsDisplay[OrgLocationSortField.CITY_STATE_PROVINCE]
        "
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.CITY_STATE_PROVINCE] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="
          !locationColumnsDisplay[OrgLocationSortField.CITY_STATE_PROVINCE]
        "
      >
        {{ location.address | address: "cityState" | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.POSTAL_CODE">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.POSTAL_CODE]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.POSTAL_CODE] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center white-arrow"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.POSTAL_CODE]"
      >
        {{ location.address.postalCode | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.COUNTRY">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.COUNTRY]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.COUNTRY] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.COUNTRY]"
      >
        <span
          [matTooltipPosition]="position"
          [matTooltip]="location.address.countryCode | country | default"
        >
          {{ location.address.countryCode | default }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.ORG">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.ORG]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.ORG] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.ORG]"
      >
        <span
          [matTooltip]="location.organization.name | default"
          [matTooltipPosition]="position"
        >
          {{ location.organization.shortName | default }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="OrgLocationSortField.FAR_COUNT">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.FAR_COUNT]"
      >
        {{ OrgLocationSortLabels[OrgLocationSortField.FAR_COUNT] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay[OrgLocationSortField.FAR_COUNT]"
      >
        <span *ngIf="!location.farcount">0</span>
        <button
          mat-button
          color="primary"
          *ngIf="location.farcount > 0 && canViewFAR"
          (click)="
            navigateToFARs(
              location.farcount,
              '../fars',
              'locationId',
              location.id
            )
          "
        >
          {{ location.farcount }}
        </button>
        <span *ngIf="location.farcount > 0 && !canViewFAR">{{
          location.farcount
        }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="!locationColumnsDisplay['actions']"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let location"
        [hidden]="!locationColumnsDisplay['actions']"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            [appLocationEdit]="location.id"
            (appLocationEdited)="onEdited($event)"
            id="view-button"
          >
            <ng-container *ngIf="!location.isEditable">
              <mat-icon>visibility</mat-icon> View
            </ng-container>
            <ng-container *ngIf="location.isEditable">
              <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
            </ng-container>
          </button>
          <button
            *ngIf="location.isEditable"
            [disabled]="location.farcount > 0"
            [matTooltip]="
              location.farcount > 0
                ? 'Assigned Locations cannot be deleted'
                : ''
            "
            mat-menu-item
            (click)="deleteLocation(location.id)"
            id="delete-button"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="locationColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: locationColumns"></mat-row>
  </mat-table>
</div>
