<mat-card
  appearance="outlined"
  *ngIf="clickable"
  [routerLink]="[filterRouterLink]"
  [queryParams]="filterQueryParams"
  class="text-center"
  [class.insight-clickable]="clickable"
  matRipple
>
  <mat-card-header class="justify-content-center">
    <mat-icon class="clickable-icon" *ngIf="!filterMode">open_in_new</mat-icon>
    <mat-icon
      class="clickable-icon"
      svgIcon="filter-variant"
      *ngIf="filterMode"
    ></mat-icon>
    <mat-card-title *ngIf="icon">
      <mat-icon data-cy="insight-icon">{{ icon }}</mat-icon>
    </mat-card-title>
    <mat-card-subtitle data-cy="insight-value"
      ><h5>{{ value }}</h5></mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content data-cy="insight-label">{{ label }}</mat-card-content>
</mat-card>

<!--This is specifically for Linked Foreign Visitors Insight in FV View-->
<mat-card
  appearance="outlined"
  [class]="result | statusMapper: 'color' | default"
  [ngClass]="{
    'textcolor-white': (result && result !== 'YELLOW') || isToggled,
    'bg-primary': isToggled
  }"
  *ngIf="!clickable && showClickable"
  [class.insight-clickable]="showClickable"
  [class.insight-clickable.hover]="result !== 'MACHINE_RED'"
  [style]="result === 'MACHINE_RED' ? { cursor: 'pointer' } : ''"
  class="text-center"
>
  <mat-card-header class="justify-content-center">
    <mat-icon class="clickable-icon" *ngIf="!filterMode">open_in_new</mat-icon>
    <mat-icon
      class="clickable-icon"
      svgIcon="filter-variant"
      *ngIf="filterMode"
    ></mat-icon>
    <mat-card-title *ngIf="icon">
      <mat-icon data-cy="insight-icon">{{ icon }}</mat-icon>
    </mat-card-title>
    <mat-card-subtitle data-cy="insight-value"
      ><h5
        [ngClass]="{
          'textcolor-white': (result && result !== 'YELLOW') || isToggled
        }"
      >
        {{ value }}
      </h5></mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content data-cy="insight-label">{{ label }} </mat-card-content>
</mat-card>

<mat-card
  appearance="outlined"
  *ngIf="!clickable && !showClickable"
  class="text-center"
>
  <mat-card-header class="justify-content-center">
    <mat-card-title *ngIf="icon">
      <mat-icon data-cy="insight-icon">{{ icon }}</mat-icon>
    </mat-card-title>
    <mat-card-subtitle data-cy="insight-value"
      ><h5>{{ value }}</h5></mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content data-cy="insight-label">{{ label }}</mat-card-content>
</mat-card>
