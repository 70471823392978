<div
  class="container-fluid d-flex align-items-left bg-white px-3 py-2"
  *ngIf="isActive()"
  style="border-top: 2px solid rgba(0, 50, 90, 0.67)"
>
  <div class="col">
    <mat-chip-listbox>
      <ng-container *ngFor="let filter of filterFields">
        <ng-container *ngIf="isFilterActive(filter, filterParams)">
          <mat-chip-option
            *ngFor="let splitVal of splitFilterValues(filter)"
            [id]="'filter-chip-' + filter.name"
            [attr.data-cy]="'filter-chip-' + filter.name"
            class="bg-primary text-white cursor-pointer"
            (click)="clear(filter, splitVal)"
          >
            <ng-container *ngIf="filter.type === FilterConfigType.INPUT">
              {{ filter.label }} = {{ splitVal }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.DATE">
              {{ filter.label }} = {{ splitVal | date }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.DATE_RANGE">
              <ng-container
                *ngIf="
                  filterParams[filter.name + 'Start'] &&
                  !filterParams[filter.name + 'End']
                "
              >
                {{ filter.label }} after
                {{ filterParams[filter.name + "Start"] | date }} </ng-container
              ><ng-container
                *ngIf="
                  !filterParams[filter.name + 'Start'] &&
                  filterParams[filter.name + 'End']
                "
              >
                {{ filter.label }} before
                {{ filterParams[filter.name + "End"] | date }} </ng-container
              ><ng-container
                *ngIf="
                  filterParams[filter.name + 'Start'] &&
                  filterParams[filter.name + 'End']
                "
              >
                {{ filter.label }} between
                {{ filterParams[filter.name + "Start"] | date }} and
                {{ filterParams[filter.name + "End"] | date }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.SELECT">
              {{ filter.label }} =
              {{ resolveOptValLabel(splitVal, filter) }}
            </ng-container>
            <ng-container
              *ngIf="filter.type === FilterConfigType.BUTTON_TOGGLE"
            >
              {{ resolveOptValLabel(splitVal, filter) }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.TOGGLE">
              {{ filter.label }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.COUNTRY">
              {{ filter.label }} =
              {{ splitVal | country: "name" }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.ORG">
              {{ filter.label }} =
              {{ filterRefObjs[filter.name] | getOrgName: "short" }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.FN">
              {{ filter.label }} =
              {{ filterRefObjs[filter.name] | fullname: "surnameFirst" }}
            </ng-container>
            <ng-container *ngIf="filter.type === FilterConfigType.USER_ROLE">
              {{ filter.label }} =
              {{ splitVal | roleLabel }}
            </ng-container>
            <mat-icon class="ml-1 text-white cursor-pointer" matChipTrailingIcon
              >clear</mat-icon
            ></mat-chip-option
          >
        </ng-container>
      </ng-container>
    </mat-chip-listbox>
  </div>
  <div class="col-1">
    <button
      id="'filter-chip-clear-all'"
      data-cy="filter-chip-clear-all"
      mat-icon-button
      matTooltip="Clear All Filters"
      style="float: right"
      (click)="clearAll()"
    >
      <mat-icon>clear_all</mat-icon>
    </button>
  </div>
</div>
