import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { FvService } from '@app/shared/services';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnDestroy, OnInit {
  @Input() filterParams: FilterParams = new FilterParams();
  @Input() totalElements?: number;
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() pageSize?: number = 10;
  @Input() pageIndex?: number = 0;
  @Input() showFirstLastButtons: boolean = true;
  @Input() enableQuickFilters: boolean = false;
  @Input() screeningReview: boolean = false;
  @Input() hidePageSize: boolean = false;
  @Input() hideBorderTop: boolean = false;
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() showPaginator: boolean = true;
  @Input() showWarningHeader: boolean = false;
  metrics: any = {};
  pageSizeOptions: number[] = [5, 10, 20, 100];
  subscription: Subscription;

  constructor(private fvService: FvService) {}

  ngOnInit() {
    this.subscription = this.paginator.page.subscribe((event) =>
      this.handlePageEvent(event)
    );
    if (this.enableQuickFilters) {
      this.getMetrics();
    }
  }
  getMetrics() {
    let params = new FilterParams({
      sortBy: 'createdDate',
      direction: 'DESC',
      pageNum: 0,
      pageSize: 1,
      screeningEligible: false,
    });
    forkJoin([this.fvService.getFvsMetrics(), this.fvService.find(params)])
      .pipe(take(1))
      .subscribe(([fvMetrics, ineligibleScreenings]) => {
        this.metrics = fvMetrics;
        this.metrics.ineligibleScreenings =
          ineligibleScreenings.totalElements || 0;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateFilterParams(type: string, value: any) {
    this.getMetrics();
    delete this.filterParams['status'];
    delete this.filterParams['result'];
    if (!this.screeningReview) delete this.filterParams['screeningEligible'];
    this.filterParams[type] = value;
    this.filterParamsChange.emit(this.filterParams);
  }

  //handles changes from user selection
  handlePageEvent(event: PageEvent) {
    this.filterParams.pageSize = event.pageSize;
    this.filterParams.pageNum = event.pageIndex;
    this.filterParamsChange.emit(this.filterParams);
  }
}
