<fieldset>
  <legend *ngIf="legend.length">
    {{ legend }}
  </legend>
  <div ngForm #form="ngForm" class="address-form row {{ cols }}">
    <app-country-select
      #countrySelect
      class="col"
      [required]="required"
      [placeholder]="countryPlaceholder"
      [(ngModel)]="value.countryCode"
      [disabled]="disabled"
      (emitSelect)="updateLocalityField()"
      (ngModelChange)="emitChangeEvent(); checkCountry()"
      (blur)="onTouched()"
      name="countryCode"
      id="address-country-code"
      floatLabel="auto"
      ngDefaultControl
    ></app-country-select>
    <mat-form-field class="col" [appearance]="appearance">
      <mat-label>Address Line 1</mat-label>
      <input
        matInput
        [(ngModel)]="value.line1"
        [disabled]="disabled"
        [required]="required"
        name="line1"
        maxlength="256"
        (ngModelChange)="emitChangeEvent()"
        (blur)="onTouched()"
        id="address-line1"
      />
      <mat-error>Address Line 1 is Required</mat-error>
    </mat-form-field>
    <mat-form-field class="col" [appearance]="appearance">
      <mat-label>Address Line 2</mat-label>
      <input
        matInput
        [disabled]="disabled"
        [(ngModel)]="value.line2"
        name="line2"
        maxlength="256"
        (ngModelChange)="emitChangeEvent()"
        (blur)="onTouched()"
        id="address-line2"
      />
      <mat-icon
        matSuffix
        svgIcon="help-circle"
        matTooltip="Any secondary address information such as suite, building, room, appartment numbers should be added here"
      ></mat-icon>
    </mat-form-field>
    <mat-form-field class="col" [appearance]="appearance">
      <mat-label>City</mat-label>
      <input
        matInput
        [disabled]="disabled"
        [required]="required"
        [(ngModel)]="value.city"
        name="city"
        maxlength="256"
        (ngModelChange)="emitChangeEvent()"
        (blur)="onTouched()"
        id="address-city"
      />
      <mat-error>City is Required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col" *ngIf="!value.countryCode">
      <mat-label>State/Province</mat-label>
      <input
        id="contactLocalityDisabledInput"
        [required]="required"
        name="contactLocality"
        maxlength="128"
        matInput
        disabled
      />
      <mat-error>State/Province is Required</mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col"
      *ngIf="
        value.countryCode &&
        countryList[value.countryCode]?.zones &&
        countryList[value.countryCode]?.zones.length === 0
      "
    >
      <mat-label>{{ countryList[value.countryCode].zone }}</mat-label>
      <input
        id="contactLocalityInput"
        name="contactLocality"
        matInput
        [(ngModel)]="value.stateProvince"
        [disabled]="disabled"
        [required]="required"
        (ngModelChange)="emitChangeEvent()"
        (blur)="onTouched()"
        [placeholder]="countryList[value.countryCode]?.zone"
      />
      <mat-error
        >{{ countryList[value.countryCode].zone }} is Required</mat-error
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col"
      *ngIf="
        value.countryCode &&
        countryList[value.countryCode] &&
        countryList[value.countryCode]?.zones.length > 0
      "
    >
      <mat-label>{{ countryList[value.countryCode].zone }}</mat-label>

      <mat-select
        id="contactLocalityDropdown"
        name="contactLocality"
        (blur)="onTouched()"
        [(ngModel)]="value.stateProvince"
        [disabled]="disabled"
        [required]="required"
        (ngModelChange)="emitChangeEvent()"
      >
        <mat-option selected disabled
          >Choose {{ countryList[value.countryCode]?.zone }}</mat-option
        >
        <mat-option
          *ngFor="
            let zone of countryList[value.countryCode]?.zones
              | orderByImpure: ['name']
          "
          [value]="zone.name"
        >
          {{ zone.name }}
        </mat-option>
      </mat-select>
      <mat-error
        >{{ countryList[value.countryCode].zone }} is Required</mat-error
      >
    </mat-form-field>

    <mat-form-field
      class="col"
      [appearance]="appearance"
      *ngIf="!value.countryCode"
    >
      <mat-label>Postal code / ZIP code</mat-label>
      <input
        matInput
        [(ngModel)]="value.postalCode"
        [disabled]="disabled"
        [required]="required"
        name="postalCode"
        maxlength="15"
        (ngModelChange)="emitChangeEvent()"
        validPostalCode
        (blur)="onTouched()"
        id="address-postal-code-no-country"
      />
      <mat-error>Postal Code / ZIP Code is Required</mat-error>
    </mat-form-field>
    <mat-form-field
      class="col"
      [appearance]="appearance"
      *ngIf="value.countryCode"
    >
      <mat-label>{{ countryList[value.countryCode]?.postalCode }}</mat-label>
      <input
        matInput
        [(ngModel)]="value.postalCode"
        [disabled]="disabled"
        [required]="required"
        name="postalCode"
        maxlength="15"
        (ngModelChange)="emitChangeEvent()"
        validPostalCode
        (blur)="onTouched()"
        id="address-postal-code-country"
      />
      <mat-error
        >{{ countryList[value.countryCode]?.postalCode }} is Required</mat-error
      >
    </mat-form-field>
  </div>
</fieldset>
