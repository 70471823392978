<div
  id="search-toolbar"
  class="
    search-toolbar
    container-fluid
    d-flex
    align-items-center
    bg-white
    px-3
    py-2
  "
>
  <div class="mat-headline-5 my-0 mr-3"><ng-content></ng-content></div>
  <app-filter
    #appFilter
    class="mr-3 flex-grow-1"
    [placeholder]="filterPlaceholder"
    [filterParams]="filterParams"
    (filterParamsChange)="onFilterParamChange($event)"
    [filterFields]="filterFields"
  ></app-filter>
  <ng-container *ngIf="!compactCard">
    <app-sort
      class="mr-3"
      *ngIf="sortFields.length && view === 'card'"
      [sortFields]="sortFields"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
    ></app-sort>
  </ng-container>
  <ng-container *ngIf="!hideViewToggle">
    <app-view
      *ngIf="!compactCard"
      [view]="view"
      (viewChange)="onViewChange($event)"
    ></app-view>
  </ng-container>
  <div class="flex-fill"></div>
  <app-column-chooser
    *ngIf="view !== 'card' && columns?.length"
    matTooltip="Column Selection"
    class="mb-1"
    [dataList]="columns"
    [tableName]="tableName"
    [labels]="columnLabels"
  ></app-column-chooser>
  <div *ngIf="!compactCard">
    <ng-container *ngFor="let button of actionButtons; let i = index">
      <ng-container *appIfRoles="button.roles">
        <button
          *ngIf="button.bulkUpload"
          id="{{ button.label }}-bulk-upload-button-{{ i }}"
          class="ml-3"
          mat-flat-button
          [appBulkUpload]="button.bulkUpload"
          (appBulkUploaded)="refresh()"
          [routerLink]="button.routerLink?.length ? [button.routerLink] : []"
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
            <span class="button-group-text" *ngIf="button.label">
              {{ button.label }}</span
            >
          </div>
        </button>
        <button
          *ngIf="button?.locationEdit"
          id="{{ button.label }}-loc-edit-button-{{ i }}"
          class="ml-3"
          mat-flat-button
          [appLocationEdit]
          (appLocationEdited)="refresh()"
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
            <span class="button-group-text" *ngIf="button.label">
              {{ button.label }}</span
            >
          </div>
        </button>
        <button
          *ngIf="button?.orgEdit"
          id="{{ button.label }}-org-edit-button-{{ i }}"
          class="ml-3"
          mat-flat-button
          [appOrganizationEdit]
          (appOrganizationEdited)="refresh()"
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
            <span class="button-group-text" *ngIf="button.label">
              {{ button.label }}</span
            >
          </div>
        </button>
        <button
          *ngIf="button?.userDialog"
          id="new-user-acct-btn"
          class="ml-3"
          mat-flat-button
          appUserAccountDialog
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
            <span class="button-group-text" *ngIf="button.label">
              {{ button.label }}</span
            >
          </div>
        </button>
        <button
          *ngIf="button?.routerLink"
          id="{{ button.label }}-action-button-{{ i }}"
          class="ml-3"
          mat-flat-button
          [routerLink]="button.routerLink?.length ? [button.routerLink] : []"
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon *ngIf="button.menuIcon">{{ button.menuIcon }}</mat-icon>
            <span class="button-group-text" *ngIf="button.label">
              {{ button.label }}</span
            >
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-active-search-display
  [filterFields]="filterFields"
  [filterParams]="filterParams"
  (filterParamsChange)="onFilterParamChange($event)"
></app-active-search-display>
<mat-progress-bar
  color="accent"
  [mode]="loading ? 'indeterminate' : 'determinate'"
></mat-progress-bar>
