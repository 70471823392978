import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material/material.module';
import { ComponentsModule } from '@app/shared/components/components.module';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import {
  FnCardComponent,
  FnLatestScreeningComponent,
  FnMetadataComponent,
  FnProfileComponent,
} from '@app/foreign-nationals';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';

const SHARED_COMPONENTS: any[] = [
  FnCardComponent,
  FnProfileComponent,
  FnLatestScreeningComponent,
  FnMetadataComponent,
];

@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    NgPipesModule,
  ],
  exports: SHARED_COMPONENTS,
})
export class ForeignNationalsModule {}
