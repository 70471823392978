// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';
// Components
import { AccountDialogDirective } from '@app/admin/accounts/accounts-collection/account-dialog/account-dialog.component';
import {
  BulkUploadComponent,
  BulkUploadDirective,
  AboutDialogComponent,
  AboutDialogDirective,
  AddressDialogComponent,
  AddressDialogDirective,
  AttachmentsComponent,
  AuthEventsDialogComponent,
  AuthEventsDialogDirective,
  ChangePasswordComponent,
  ColumnChooserComponent,
  CommentDialogComponent,
  CommentDialogDirective,
  CommentListComponent,
  ConfirmDialogComponent,
  ContactSupportDialogComponent,
  ContactSupportDialogDirective,
  DialogBoxComponent,
  DialogComponent,
  EmailDialogComponent,
  EmailDialogDirective,
  EmptyStateComponent,
  FilterComponent,
  FvContactDialogComponent,
  FvContactFormComponent,
  FvDetailsComponent,
  FvEducationDialogComponent,
  FvEducationFormComponent,
  FvEmailDisplayComponent,
  FvListItemComponent,
  FvPhoneDisplayComponent,
  InfoBannerComponent,
  InsightCardComponent,
  LandingPageCardComponent,
  MultiItemContainerComponent,
  NoResultFoundComponent,
  PageHeaderComponent,
  PaginatorComponent,
  PassportHelpComponent,
  PasswordRulesComponent,
  PhoneDialogComponent,
  PhoneDialogDirective,
  ProfilePicSelectComponent,
  RelatedAssociatesComponent,
  ResourceUserDetailsComponent,
  SearchLocationsDialogComponent,
  SearchToolbarComponent,
  SortComponent,
  SpinnerOverlayComponent,
  StepperFooterComponent,
  TableIconComponent,
  TimeoutDialogComponent,
  UserAvatarComponent,
  UserRoleChipsComponent,
  ViewComponent,
  ActiveSearchDisplayComponent,
  AdvancedSearchComponent,
  AdvancedSearchDirective,
} from '@shared/components';
import { ActiveAlertsComponent } from './components/active-alerts/active-alerts.component';
import { SelectFvComponent } from './controls';
import { TimelineModule } from './components/timeline/timeline.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WebcamModule } from 'ngx-webcam';
import { ConfirmMatchDirective } from '@shared/validators/confirm-match.validator';
import { ValidPasswordDirective } from '@shared/validators/password.validator';
import { MetricsObservable, UtilityConfigService } from './services';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserAcceptedObservable } from './services/user-accepted-observable.service';
import { ComponentsModule } from './components/components.module';
import { ColumnChooserEventObservable } from './components/column-chooser/column-chooser-observable.service';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ControlsModule } from './controls/controls.module';
import { ScreeningModule } from './components/screening/screening.module';
import { CmtModule } from './cmt/cmt.module';
import { ForeignNationalsModule } from '@app/foreign-nationals/foreign-nationals.module';
import { NoOpenEndedDatesDirective } from '@shared/validators/no-open-ended-dates.validator';
import { SelectFarComponent } from '@shared/controls/select-far/select-far.component';

const SHARED_MODULES: any[] = [
  CmtModule,
  CommonModule,
  ComponentsModule,
  ControlsModule,
  DirectivesModule,
  FileUploadModule,
  FormsModule,
  ImageCropperModule,
  MaterialModule,
  MomentModule,
  NgPipesModule,
  NgxMatSelectSearchModule,
  PipesModule,
  ReactiveFormsModule,
  RouterModule,
  ScreeningModule,
  TimelineModule,
  WebcamModule,
  ForeignNationalsModule,
];

const SHARED_COMPONENTS: any[] = [
  AboutDialogComponent,
  ActiveAlertsComponent,
  ActiveSearchDisplayComponent,
  AddressDialogComponent,
  AdvancedSearchComponent,
  AttachmentsComponent,
  AuthEventsDialogComponent,
  AuthEventsDialogComponent,
  AuthEventsDialogDirective,
  BulkUploadComponent,
  ChangePasswordComponent,
  ColumnChooserComponent,
  CommentDialogComponent,
  CommentDialogDirective,
  CommentListComponent,
  ConfirmDialogComponent,
  ContactSupportDialogComponent,
  DialogBoxComponent,
  DialogComponent,
  EmailDialogComponent,
  EmptyStateComponent,
  FilterComponent,
  FvContactDialogComponent,
  FvContactFormComponent,
  FvDetailsComponent,
  FvEducationDialogComponent,
  FvEducationFormComponent,
  FvEmailDisplayComponent,
  FvListItemComponent,
  FvPhoneDisplayComponent,
  InfoBannerComponent,
  InsightCardComponent,
  LandingPageCardComponent,
  MultiItemContainerComponent,
  MultiItemContainerComponent,
  NoResultFoundComponent,
  PageHeaderComponent,
  PaginatorComponent,
  PassportHelpComponent,
  PasswordRulesComponent,
  PhoneDialogComponent,
  ProfilePicSelectComponent,
  RelatedAssociatesComponent,
  ResourceUserDetailsComponent,
  SearchLocationsDialogComponent,
  SearchToolbarComponent,
  SelectFarComponent,
  SelectFvComponent,
  SortComponent,
  SortComponent,
  SpinnerOverlayComponent,
  StepperFooterComponent,
  TableIconComponent,
  TimeoutDialogComponent,
  TimeoutDialogComponent,
  UserAvatarComponent,
  UserRoleChipsComponent,
  ViewComponent,
  ViewComponent,
];
const SHARED_DIRECTIVES: any[] = [
  AboutDialogDirective,
  AccountDialogDirective,
  AdvancedSearchDirective,
  BulkUploadDirective,
  ConfirmMatchDirective,
  ContactSupportDialogDirective,
  NoOpenEndedDatesDirective,
  ValidPasswordDirective,
  PhoneDialogDirective,
  EmailDialogDirective,
  AddressDialogDirective,
];

@NgModule({
  declarations: [...SHARED_DIRECTIVES, ...SHARED_COMPONENTS],
  imports: SHARED_MODULES,
  exports: [...SHARED_MODULES, ...SHARED_DIRECTIVES, ...SHARED_COMPONENTS],
  providers: [
    UtilityConfigService,
    MetricsObservable,
    UserAcceptedObservable,
    ColumnChooserEventObservable,
  ],
})
export class SharedModule {}
