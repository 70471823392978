import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterParams, View } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { SortSelect } from '../sort/sort.component';
import { MetricsObservable } from '@app/shared/services';
import { FilterComponent } from '../filter/filter.component';
import { CollectionView } from '../view/view.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FilterConfig } from '@shared/components/advanced-search/filter-config';

@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
})
export class SearchToolbarComponent {
  @ViewChild('appFilter') appFilter: FilterComponent;
  @Input() filterPlaceholder: string = 'Given Name, Surname';
  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[] = [];
  @Input() sortFields: SortSelect[] = [];
  @Input() columns: string[];
  @Input() columnLabels: any;
  @Input() tableName: string = '';
  @Input() actionButtons: ActionButton[];
  @Input() collectionViews: View[];
  @Input() compactCard: boolean = false;
  @Input() view: CollectionView = 'table';
  @Input() loading: boolean = false;
  @Output() viewChange = new EventEmitter<CollectionView>();
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  _hideViewToggle: boolean = false;

  @Input() set hideViewToggle(value: any) {
    this._hideViewToggle = coerceBooleanProperty(value);
  }
  get hideViewToggle(): boolean {
    return this._hideViewToggle;
  }

  constructor(private _MetricsObservable: MetricsObservable) {}

  onViewChange(view: CollectionView) {
    this.viewChange.emit(view);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.refresh();

    this._MetricsObservable.send('update');
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }
}
