import {
  AbstractControl,
  NgModel,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { isString } from 'lodash';

/** field and fieldToMatch must match
 * ### example
 * password and confirm_password must match
 */
export function confirmMatch(
  fieldControl: AbstractControl | null,
  fieldToMatchControl: AbstractControl | null
): ValidationErrors | null {
  // return null if controls haven't initialised yet
  if (!fieldControl || !fieldToMatchControl) {
    return null;
  }

  // need to set error form comfrim_password because mat-error needs the error to trigger
  // if the fields do not match, set error for fieldToMatch
  return fieldControl.value !== fieldToMatchControl.value
    ? { mustMatch: true }
    : null;
}

export function confirmMatchValidator(field: string, fieldToMatch: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    const fieldControl: AbstractControl | null = control.get(field); // first field in form like password
    const fieldToMatchControl: AbstractControl | null =
      control.get(fieldToMatch); // second control to match like confirm_password
    const errors = confirmMatch(fieldControl, fieldToMatchControl);

    fieldToMatchControl?.setErrors(errors);
    return errors;
  };
}

@Directive({
  selector: '[confirmMatch]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ConfirmMatchDirective, multi: true },
  ],
})
export class ConfirmMatchDirective implements Validator {
  @Input() confirmMatch: AbstractControl | NgModel;

  private valueChangesSub: Subscription;

  validate(selfControl: AbstractControl): ValidationErrors | null {
    const otherControl = isString(this.confirmMatch)
      ? selfControl.root.get(this.confirmMatch as string)
      : (this.confirmMatch as AbstractControl);

    if (this.valueChangesSub) {
      this.valueChangesSub.unsubscribe();
    }

    // this code works when user types in other control putting the error in self control
    if (otherControl) {
      this.valueChangesSub = otherControl.valueChanges.subscribe(
        (_otherControlValue) => {
          const errors = confirmMatch(selfControl, otherControl);
          selfControl.setErrors(errors);
        }
      );
    }

    return confirmMatch(selfControl, otherControl);
  }
}
