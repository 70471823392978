<form id="locationForm" #locationForm="ngForm">
  <mat-form-field [appearance]="appearance">
    <mat-label>Name</mat-label>
    <input
      matInput
      required
      [disabled]="value.id && !value.isEditable"
      [(ngModel)]="value.name"
      name="name"
      maxlength="150"
      (ngModelChange)="emitChangeEvent()"
      id="location-name"
    />
    <mat-error>Location Name is required</mat-error>
  </mat-form-field>
  <app-address
    #address
    [disabled]="value.id && !value.isEditable"
    [required]="true"
    [columns]="2"
    [countryPlaceholder]="'Choose Country'"
    [(ngModel)]="value.address"
    (ngModelChange)="emitChangeEvent()"
    name="location"
  ></app-address>
  <app-organization-select
    [required]="true"
    [disabled]="value.id"
    [(ngModel)]="value.organization"
    (ngModelChange)="emitChangeEvent()"
    name="organization"
    *appIfRoles="Role.sv_admin"
  >
  </app-organization-select>
</form>
