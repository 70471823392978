<div class="d-flex flex-column h-100 change-password-container">
  <h3 mat-dialog-title class="d-flex flex-row align-items-center pt-2">
    <mat-icon class="mr-3">lock</mat-icon> <span>Change Password</span>
  </h3>
  <mat-dialog-content>
    <form
      #pwdForm="ngForm"
      id="pwdForm"
      class="d-flex flex-column flex-grow-1 mt-2"
    >
      <mat-form-field appearance="outline" class="pb-2">
        <mat-label>Current Password</mat-label>
        <input
          matInput
          required
          [type]="hide.oldPassword ? 'password' : 'text'"
          placeholder="Enter Current Password"
          [(ngModel)]="oldPassword"
          name="oldPassword"
        />
        <mat-icon matSuffix (click)="hide.oldPassword = !hide.oldPassword">{{
          hide.oldPassword ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-error>Current Password is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="pb-4 mb-4">
        <mat-label>New Password</mat-label>
        <input
          #passwordInput="ngModel"
          matInput
          [type]="hide.newPassword ? 'password' : 'text'"
          placeholder="Enter New Password"
          [(ngModel)]="newPassword"
          name="newPassword"
          [validPassword]
        />
        <mat-icon matSuffix (click)="hide.newPassword = !hide.newPassword">{{
          hide.newPassword ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-hint>
          <app-password-rules [control]="passwordInput"></app-password-rules>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          [type]="hide.confirmPassword ? 'password' : 'text'"
          placeholder="Confirm New Password"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          [confirmMatch]="passwordInput"
        />
        <mat-icon
          matSuffix
          (click)="hide.confirmPassword = !hide.confirmPassword"
        >
          {{ hide.confirmPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
        <mat-error>Passwords Must Match</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="flex-row">
    <button mat-button mat-dialog-close>Cancel</button>
    <span class="flex-grow-1"></span>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      form="pwdForm"
      [disabled]="!pwdForm || pwdForm.invalid || busy"
      (click)="submit()"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</div>
