import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ForeignVisitorsRoutingModule } from './foreign-visitors-routing.module';
import { BiographicComponent } from './fv/biographic/biographic.component';
import { FVComponent } from './fv/creation.component';
import { ContactComponent } from './fv/contact/contact.component';
import { PassportComponent } from './fv/passport/passport.component';
import { VisaComponent } from './fv/visa/visa.component';
import { FvViewComponent } from './fvview/fvview.component';
import { FvInfoComponent } from './fvview/fvinfo/fvinfo.component';
import { FvActivityComponent } from './fvview/fvactivity/fvactivity.component';
import { FvviewEmploymentComponent } from './fvview/fvview-employment/fvview-employment.component';
import { FvviewAttachmentsComponent } from './fvview/fvview-attachments/fvview-attachments.component';
import { FvSummaryComponent } from './fv/fv-summary/fv-summary.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CollectionComponent } from './dashboard/collection/collection.component';
import { GridCollectionComponent } from './dashboard/grid-collection/grid-collection.component';
import { FvviewPassportsComponent } from './fvview/fvview-passports/fvview-passports.component';
import { FvTableComponent } from './dashboard/fv-table/fv-table.component';
import { VisitorBaselineComponent } from './fv/visitor-baseline/visitor-baseline.component';
import { FvAddressCreationComponent } from './fv/fv-address-creation/fv-address-creation.component';
import { EmploymentFormComponent } from './fvview/fvview-employment/employment-form/employment-form.component';
import { SocialMediaDialogComponent } from './fvview/fvview-social-media/social-media-dialog/social-media-dialog.component';
import { SocialMediaFormComponent } from './fvview/fvview-social-media/social-media-dialog/social-media-form/social-media-form.component';
import { BiographicDialogComponent } from './fvview/biographic-dialog/biographic-dialog.component';
import { BiographicFormComponent } from './fvview/biographic-dialog/biographic-form/biographic-form.component';
import { FvIdentificationFormComponent } from './fvview/fvidentification/identification-dialog/fv-identification-form/fv-identification-form.component';
import { FvIdentificationDialogComponent } from './fvview/fvidentification/identification-dialog/fv-identification-dialog.component';
import { PhysicalIdVehicleDialogComponent } from './fvview/fvidentification/physical-id-dialog/physical-id-vehicle-dialog.component';
import { NatlIdDialogComponent } from './fvview/fvidentification/natl-id-dialog/natl-id-dialog/natl-id-dialog.component';
import { FvviewSocialMediaComponent } from './fvview/fvview-social-media/fvview-social-media.component';
import { FvviewVisaComponent } from './fvview/fvview-visa/fvview-visa.component';
import { VisaDialogComponent } from './fvview/fvview-visa/visa-dialog/visa-dialog.component';
import { DeleteFvDirective } from './delete-fv.directive';
import { UspDeleteDialogComponent } from './usp-delete-dialog/usp-delete-dialog.component';
import { FvLinkedListComponent } from './fvview/fv-linked-list/fv-linked-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { PassportDialogComponent } from './fv/passport/passport-dialog.component';
import { FvViewAdditionalInfoComponent } from './fvview/fv-view-additional-info/fv-view-additional-info.component';
import { FvViewEducationComponent } from './fvview/fvview-education/fvview-education.component';
import { FarListComponent } from './fvview/far-list/far-list.component';
import { FvViewInfoBannerComponent } from './fvview/fv-view-info-banner/fv-view-info-banner.component';
import { ScreeningInsightCardComponent } from './fvview/screening-insight-card/screening-insight-card.component';
import { FvviewContactComponent } from './fvview/fvview-contact/fvview-contact.component';
import { FvviewIdentifyingVehicleComponent } from './fvview/fvview-identifying-vehicle/fvview-identifying-vehicle.component';
import { FvviewNationalIdComponent } from './fvview/fvview-national-id/fvview-national-id.component';
import { FvScreeningEventsComponent } from './fvview/fvscreening-events/fvscreening-events.component';
import { AddFarDialogComponent } from './fvview/fvactivity/add-far-dialog/add-far-dialog.component';
@NgModule({
  declarations: [
    BiographicComponent,
    FVComponent,
    ContactComponent,
    PassportComponent,
    VisaComponent,
    FvViewComponent,
    FvInfoComponent,
    FvActivityComponent,
    PhysicalIdVehicleDialogComponent,
    NatlIdDialogComponent,
    FvviewEmploymentComponent,
    FvviewAttachmentsComponent,
    FvSummaryComponent,
    DashboardComponent,
    CollectionComponent,
    GridCollectionComponent,
    FvviewPassportsComponent,
    FvTableComponent,
    VisitorBaselineComponent,
    FvAddressCreationComponent,
    EmploymentFormComponent,
    SocialMediaDialogComponent,
    SocialMediaFormComponent,
    BiographicDialogComponent,
    BiographicFormComponent,
    FvIdentificationFormComponent,
    FvIdentificationDialogComponent,
    FvviewSocialMediaComponent,
    FvviewVisaComponent,
    VisaDialogComponent,
    DeleteFvDirective,
    UspDeleteDialogComponent,
    FvLinkedListComponent,
    FvViewAdditionalInfoComponent,
    PassportDialogComponent,
    FvViewEducationComponent,
    ScreeningInsightCardComponent,
    FvViewInfoBannerComponent,
    FvviewContactComponent,
    FvviewIdentifyingVehicleComponent,
    FvviewNationalIdComponent,
    FvScreeningEventsComponent,
    AddFarDialogComponent,
  ],

  imports: [
    SharedModule,
    ForeignVisitorsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    FarListComponent,
    MatSlideToggleModule,
    MatListModule,
  ],
  exports: [
    FVComponent,
    FvViewComponent,
    CollectionComponent,
    FvTableComponent,
    FvLinkedListComponent,
  ],
})
export class ForeignVisitorsModule {}
