import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { OrgLocationService } from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { OrgLocation, Role, User } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { LocationFormComponent } from '../location-form/location-form.component';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss'],
})
export class LocationDialogComponent implements OnInit {
  public Role = Role;
  location: OrgLocation = {};
  user: User | null = this.authService.getUser();
  @ViewChild('locationForm') locationForm: LocationFormComponent;

  /**
   * This needs to be disabled until API can be updated.
   * Errors occur in other environments because there is no access to
   * GET api/v2/systemUsers/{id} endpoint.
   *
   @ViewChild('resourceUserDetails')
   resourceUserDetails: ResourceUserDetailsComponent;
   *
   */

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: string | null,
    public locationDialog: MatDialogRef<LocationDialogComponent>,
    private locationService: OrgLocationService,
    public authService: AuthService
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.id) {
      this.getLocation(this.id);
    } else {
      this.initNewLocation();
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.id) {
        return true;
      }
      return false;
    }
    return false;
  }

  initNewLocation() {
    this.location.organization = this.user?.organization;
  }

  getLocation(id: string) {
    this.locationService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.location = cloneDeep(data);
        /**
         *
         * This needs to be disabled until API can be updated.
         * Errors occur in other environments because there is no access to
         * GET api/v2/systemUsers/{id} endpoint.
         *
         if (this.resourceUserDetails) {
           this.resourceUserDetails.data = this.location;
           this.resourceUserDetails.getCreatedByUser();
           this.resourceUserDetails.refreshModifiedUser();
         }
         */
      });
  }

  // Handles location changes emitted from from component
  onLocationChange(location: OrgLocation) {
    this.location = location;
  }

  hasRequiredData() {
    if (
      this.location &&
      this.location.address &&
      this.location.address.line1 &&
      this.location.address.city &&
      this.location.address.stateProvince &&
      this.location.address.countryCode &&
      this.location.address.postalCode
    ) {
      return true;
    }

    if (
      this.location &&
      this.location.address &&
      (this.location.address.line1 ||
        this.location.address.line2 ||
        this.location.address.city ||
        this.location.address.stateProvince ||
        this.location.address.countryCode ||
        this.location.address.postalCode)
    ) {
      return false;
    }
    return false;
  }

  onSubmit() {
    this.disabledSubmit = true;
    this.locationService
      .save(this.location)
      .pipe(take(1))
      .subscribe((data) => {
        this.locationDialog.close(data);
      });
  }

  static openDialog(
    dialog: MatDialog,
    location?: OrgLocation
  ): MatDialogRef<LocationDialogComponent> {
    return dialog.open<LocationDialogComponent>(LocationDialogComponent, {
      data: location,
      width: '750px',
    });
  }
}
