import { Sponsor } from './sponsor.model';
import { FarLocation } from './farlocation.model';
import { FarComment } from './farcomment.model';
import { AttachmentData } from './attachment-data.model';
import { BaseModel } from './base.model';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { PermissionModel } from '@shared/models/permission.model';
import { ClassificationModel } from '@shared/models/classification.model';
import { ForeignVisitorScreeningRollup } from './foreignVisitorScreeningRollup';

export interface FAR extends BaseModel, PermissionModel, ClassificationModel {
  id?: any;
  eventTitle?: string; // (40)
  description?: string; // (2000)
  purposeType?: FarPurposeType;
  foreignVisitorScreeningRollup?: ForeignVisitorScreeningRollup;
  submittedOn?: Date;
  sponsor?: Sponsor;
  foreignAccessRequestLocations?: FarLocation[];
  numScr?: number;
  foreignVisitors?: ForeignVisitor[]; //far_fv_association
  comments?: FarComment[];
  attachmentData?: AttachmentData[];
  isUpcomingVisit?: boolean;
  deletionDate?: string;
  attachmentCount?: number;
  primaryVisit?: FarLocation;
  primaryFar?: any;
  foreignAccessRequestScreeningCounts?: any;
  isActiveFar?: boolean;
}

export enum FarPurposeType {
  IN_PERSON_MEETING = 'In Person Meeting',
  VIRTUAL_MEETING = 'Virtual Meeting',
  SYSTEM_ACCESS = 'System Access',
  FACILITY_ACCESS = 'Facility Access',
  OTHER = 'Other',
}

export const FarPurposeTypes = Object.values(FarPurposeType);

export type FARTitlePurpose = Pick<
  FAR,
  'eventTitle' | 'description' | 'purposeType'
>;

export const FarPurposeTypeIcons: { [key in FarPurposeType]: string } = {
  [FarPurposeType.FACILITY_ACCESS]: 'office-building-marker',
  [FarPurposeType.VIRTUAL_MEETING]: 'monitor-account',
  [FarPurposeType.SYSTEM_ACCESS]: 'wan',
  [FarPurposeType.IN_PERSON_MEETING]: 'human-greeting-proximity',
  [FarPurposeType.OTHER]: 'alpha-o-circle-outline',
};

export const FarPurposeTypeTooltip: { [key in FarPurposeType]: string } = {
  [FarPurposeType.FACILITY_ACCESS]:
    'Intended for visits to USG Facility by Foreign National that will have long-term and ongoing facility access as an employee, contractor, etc',
  [FarPurposeType.VIRTUAL_MEETING]: 'Virtual Meeting',
  [FarPurposeType.SYSTEM_ACCESS]:
    'USG System Access Request for Foreign National',
  [FarPurposeType.IN_PERSON_MEETING]:
    'Access to US Person(s) by a Foreign National',
  [FarPurposeType.OTHER]: 'Other',
};
