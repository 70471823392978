import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AttachmentData,
  AttachmentStatus,
  AttachmentType,
} from '@app/shared/models/attachment-data.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { isEmpty } from 'lodash';
import {
  AttachmentService,
  FarAttachmentService,
  FvAttachmentService,
  S3_HEADER_SSE,
  S3_HEADER_SSE_ID,
} from '@shared/services/attachment.service';
import { FilterParams } from '@shared/models';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService, CrudChangeType } from '@shared/services';

@Component({
  selector: 'app-attachments',
  templateUrl: 'attachments.component.html',
  styleUrls: ['attachments.component.scss'],
})
export class AttachmentsComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  AttachmentStatus = AttachmentStatus;

  fileHoverDrop = false;

  fileUpdateSubscription = Subscription.EMPTY;

  uploader: FileUploader = new FileUploader({
    itemAlias: 'file',
    method: 'PUT',
    maxFileSize: 104857600,
    disableMultipart: true,
    url: '',
  });

  uploading: { attachment: AttachmentData; item: FileItem }[] = [];

  _required = false;
  @Input()
  set required(_required: boolean) {
    this._required = coerceBooleanProperty(_required);
  }

  get required(): boolean {
    return this._required;
  }

  _disabled = false;
  @Input()
  set disabled(_disabled: boolean) {
    this._disabled = coerceBooleanProperty(_disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  buttonStyle: boolean = false;

  @Input()
  parentType: 'FAR' | 'FV' = 'FV';

  @Input()
  editable?: boolean = true;

  @Input()
  deletable?: boolean = false;

  @Input()
  selectable?: boolean = false;
  @Input()
  parentId: string;

  @Input()
  accept: string;

  @Input()
  multiple = true;

  @Input()
  type: AttachmentType = AttachmentType.DOCUMENT;

  attachments: AttachmentData[] = [];

  get count(): number {
    return this.attachments?.length || 0;
  }

  @Output()
  countChange = new EventEmitter<number>();

  @Output()
  filesChange = new EventEmitter<AttachmentData[]>();

  @Output()
  fileAdded = new EventEmitter<AttachmentData>();

  @Output()
  fileDeleted = new EventEmitter<AttachmentData>();

  selected: any = {};

  private _lastSelected?: string;

  attachmentService: AttachmentService;

  onChange = (_value: AttachmentData[]) => {};
  onTouched = () => {};

  constructor(
    private injector: Injector,
    private ref: ChangeDetectorRef,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.attachmentService =
      this.parentType === 'FAR'
        ? this.injector.get<FarAttachmentService>(FarAttachmentService)
        : this.injector.get<FvAttachmentService>(FvAttachmentService);

    this.refresh();

    this.uploader.onAfterAddingFile = (fileItem) => this.onAddFile(fileItem);
    this.uploader.onSuccessItem = (item: FileItem) => this.onFileUploaded(item);
    this.uploader.onErrorItem = () => this.alert.errorAlert('Upload Failed!');

    this.fileUpdateSubscription = this.attachmentService.changeEvent$.subscribe(
      (evt) => {
        if (evt.type === CrudChangeType.create) return;

        const found = this.attachments.findIndex((a) => a.id === evt.modelId);

        if (found < 0 || !evt.model) {
          return this.refresh();
        }

        this.attachments[found] = evt.model;
        this.emitChangeEvent();
      }
    );
  }

  onAddFile(fileItem: FileItem) {
    if (!this.editable) return;
    const attachment: AttachmentData = {
      parentId: this.parentId,
      name: fileItem.file.name,
      type: this.type,
      mimeType: fileItem.file.type,
    };

    const uploadItem = { attachment, item: fileItem };
    this.uploading.push(uploadItem);

    this.attachmentService.create(attachment).subscribe((result) => {
      uploadItem.attachment = result;
      fileItem.url = result.presignUrl as string;

      if (result.xAmzServerSideEncryption) {
        fileItem.headers = [
          { name: S3_HEADER_SSE, value: result.xAmzServerSideEncryption },
          { name: S3_HEADER_SSE_ID, value: result.xAmzServerSideEncryptionId },
        ];
      }

      this.uploader.uploadItem(fileItem);
    });
  }

  addStatusWatches() {
    const toCheck = this.attachments
      .filter((a) => a.status === AttachmentStatus.SCAN_PENDING)
      .map((a) => a.id);
    if (toCheck.length)
      this.attachmentService.watchStatus(...(toCheck as string[]));
  }

  getFilterParams() {
    return new FilterParams({
      parentId: this.parentId,
      pageSize: 10000,
      sort: { active: 'createdDate', direction: 'asc' },
    });
  }

  refresh() {
    this.attachmentService.find(this.getFilterParams()).subscribe((results) => {
      this.attachments = results.content;
      this.addStatusWatches();
      this.emitChangeEvent();
    });
  }

  onFileUploaded(fileItem: FileItem) {
    const index = this.uploading.findIndex((i) => i.item === fileItem);
    const attachment = this.uploading[index].attachment;
    this.uploading.splice(index, 1);

    this.fileAdded.emit(attachment);
    this.refresh();
    this.addStatusWatches();
    this.emitChangeEvent();
    this.alert.successAlert('Attachment Uploaded, Virus Scan pending');
  }

  @HostListener('document:click', ['$event'])
  clearSelect(_event?: MouseEvent) {
    delete this._lastSelected;
    this.selected = {};
  }

  selectFile(attachment: AttachmentData, event: MouseEvent) {
    const isShiftKey = event.shiftKey;
    const isCtrl = event.ctrlKey;
    const lastIndex = this._lastSelected
      ? this.attachments.findIndex((f) => f.id === this._lastSelected)
      : -1;
    const index = this.attachments.findIndex((f) => f.id === attachment.id);

    if (index < 0) return;
    if (!isShiftKey && !isCtrl) this.clearSelect();

    this._lastSelected = attachment.id;

    if (isShiftKey && lastIndex >= 0) {
      for (
        let i = Math.min(lastIndex, index);
        i <= Math.max(lastIndex, index);
        i++
      ) {
        this.selected[this.attachments[i].id as string] = true;
      }
    } else if (isCtrl) {
      this.selected[attachment.id as string] =
        !this.selected[attachment.id as string];
    } else {
      this.selected[attachment.id as string] = true;
    }
  }

  get selectedAttachments(): AttachmentData[] {
    return this.attachments.filter((a) => !!this.selected[a.id as string]);
  }

  openFile(attachment: AttachmentData) {
    if (attachment.status !== AttachmentStatus.CLEAN) return;
    this.attachmentService.get(attachment.id).subscribe((result) => {
      window.open(result.presignUrl);
    });
  }

  get showFileList(): boolean {
    return (
      this.disabled || !isEmpty(this.attachments) || !isEmpty(this.uploading)
    );
  }

  deleteSelected() {
    const selectedAttachments = this.selectedAttachments;
    if (!selectedAttachments.length) return;

    if (selectedAttachments.length === 1)
      return this.delete(selectedAttachments[0]);

    this.alert
      .confirmDelete({
        title: `Confirm Delete Attachments`,
        message: `Are you sure you want to delete ${selectedAttachments.length} attachments?`,
        performAction: () => {
          const deletes = selectedAttachments.map((a) => this.doDelete(a));
          return forkJoin(deletes);
        },
        successMsg: `Attachments deleted.`,
      })
      .subscribe((_confirm) => this.emitChangeEvent());
  }

  delete(attachment: AttachmentData) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Attachment`,
        message: `Are you sure you want to delete ${attachment.name}?`,
        performAction: () => this.doDelete(attachment),
        successMsg: `Attachment ${attachment.name} deleted.`,
      })
      .subscribe((_confirm) => {
        this.emitChangeEvent();
      });
  }

  doDelete(attachment: AttachmentData): Observable<any> {
    const idx = this.attachments.indexOf(attachment);
    this.attachments.splice(idx, 1);
    return this.attachmentService
      .delete(attachment.id)
      .pipe(tap(() => this.fileDeleted.emit(attachment)));
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

  ngOnDestroy() {
    this.fileUpdateSubscription.unsubscribe();
  }

  emitChangeEvent() {
    this.onChange(this.attachments);
    this.filesChange.emit(this.attachments);
    this.countChange.emit(this.count);
  }

  public writeValue(value: AttachmentData[]) {
    this.attachments = value;
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
