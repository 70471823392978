<ng-container *ngIf="far$ | async as far; else spinner">
  <mat-toolbar class="border-bottom">
    <div class="row w-100">
      <div class="col-4 align-items-center" style="display: flex">
        <div class="pr-2 mb-2">
          <span
            class="d-flex w-100 mt-2"
            style="margin-bottom: -8px"
            [matTooltip]="far.eventTitle | titlecase | default"
          >
            {{ far.eventTitle | titlecase | shorten: 28:"..." | default }}</span
          >
          <span
            class="d-flex align-items-center"
            style="font-size: 16px"
            [matTooltip]="
              'Purpose Type: ' + far.purposeType | titlecase | default
            "
            ><mat-icon class="pr-1" svgIcon="assistant"></mat-icon
            >{{ far.purposeType | titlecase | default }}</span
          >
        </div>
        <button
          class="pr-2"
          *ngIf="far.isEditable"
          mat-icon-button
          (click)="onEdit(far, true)"
          matTooltip="Edit Title and Purpose Type"
        >
          <mat-icon svgIcon="square-edit-outline"></mat-icon>
        </button>
        <app-resource-user-details [data]="far"></app-resource-user-details>
        <mat-icon
          class="ml-2"
          [svgIcon]="'alpha-' + far.networkDomain?.toLowerCase() + '-box'"
          matTooltip="Network"
        ></mat-icon>
        <mat-icon
          class="ml-2"
          svgIcon="pencil-off-outline"
          matTooltip="Read Only"
          color="primary"
          *ngIf="!far.isEditable"
          style="opacity: 0.7"
        ></mat-icon>
        <dl class="text-center mb-0 ml-5 pr-5" *ngIf="far.deletionDate">
          <dt class="mat-small">Deletion In</dt>
          <dd
            class="mat-h3 mb-0"
            [matTooltip]="
              'Foreign Access Request Deleted in ' +
              (far.deletionDate | daysUntil) +
              ' Days'
            "
            matTooltipPosition="below"
          >
            <mat-icon
              *ngIf="parseInt(far.deletionDate | daysUntil) <= 7"
              color="warn"
              class="align-middle md-18 mr-1"
              >report</mat-icon
            >{{ far.deletionDate | daysUntil }} Days
          </dd>
        </dl>
      </div>
      <div class="col-4 pr-0 d-flex justify-content-center align-items-center">
        <app-classification-banner
          class="d-block mt-2"
          [classificationModel]="far"
        ></app-classification-banner>
      </div>
      <div
        class="col-4 justify-content-end pr-0 align-items-center"
        style="display: flex"
      >
        <!--Developer Option: Change Editability-->
        <!--<button
          mat-button
          type="button"
          class="mr-3"
          (click)="
            far.isEditable = !far.isEditable; far.deletionDate = '2023-08-01'
          "
        >
          Change Edit Mode
        </button>-->
        <button
          id="far-view-action-menu"
          *appIfRoles="FVEditRoles"
          mat-icon-button
          [matMenuTriggerFor]="actionMenu"
          matTooltip="Actions Menu"
        >
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #actionMenu>
          <button id="new-far-view" mat-menu-item [routerLink]="['/far']">
            <mat-icon>note_add</mat-icon>
            <span> New FAR </span>
          </button>
          <ng-container *ngIf="far.isEditable">
            <!-- CLASSIFY BUTTON -->
            <button
              mat-menu-item
              id="far-view-classify-btn"
              appClassify
              *appClassifyShow
              [classificationModel]="far"
              (classificationModelChange)="onClassify($event)"
            >
              <mat-icon svgIcon="shield-lock"></mat-icon>
              <span class="button-group-text">Classify</span>
            </button>
          </ng-container>
          <ng-container *ngIf="far.isDeletable">
            <span
              [matTooltip]="
                far.isEditable
                  ? 'Delete Foreign Access Request'
                  : 'Functionality Disabled - Delete Corresponding FAR on the Low Side First'
              "
            >
              <button
                [disabled]="!far.isEditable"
                id="deleteFar"
                *appIfRoles="Role.sv_admin"
                mat-menu-item
                [appDeleteFar]="far"
                [farDeletedRoute]="['/fars']"
                data-cy="delete-far"
              >
                <mat-icon [color]="far.isEditable ? 'warn' : ''"
                  >delete</mat-icon
                >
                <span
                  [ngClass]="{
                    'text-danger': far.isEditable
                  }"
                  >Delete</span
                >
              </button>
            </span>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
  <!--FAR BODY-->
  <div class="mb-1 mx-5">
    <div class="far-view__details row mt-2">
      <div class="col">
        <div class="row">
          <!--ASSOCIATED FOREIGN NATIONALS-->
          <div class="col-6">
            <app-far-view-associated-fvs
              #associatedFvs
              [isEditable]="far.isEditable ? far.isEditable : false"
              [isDeletable]="far.isDeletable ? far.isDeletable : false"
              [isRescreenable]="far.isRescreenable ? far.isRescreenable : false"
              [farId]="far.id"
              [owningOrg]="far.ownerOrganizationId"
              (addFv)="onAddFv(far)"
              (removeFv)="onRemoveFv($event)"
            ></app-far-view-associated-fvs>
          </div>
          <!--ITINERARY-->
          <div class="far-view__itinerary col-6">
            <app-far-itinerary [far]="far"></app-far-itinerary>
          </div>
        </div>
        <!--COMMENTS-->
        <div class="row mt-2">
          <div class="col">
            <mat-card
              appearance="outlined"
              class="comment-card"
              [style]="far?.comments?.length ? 'padding-bottom: 16px' : ''"
            >
              <app-comment-list
                [class]="
                  far?.comments?.length === 0 ? 'empty-comment-card' : ''
                "
                #commentList
                parentType="FAR"
                [editable]="far.isEditable"
                [deletable]="far.isDeletable"
                [parentId]="far.id"
              >
              </app-comment-list>
            </mat-card>
          </div>
        </div>
      </div>
      <!--COLLAPSEABLE SECTION-->
      <div class="col-3 collapseable-section">
        <!--SPONSOR-->
        <mat-expansion-panel
          class="flex-fill"
          #sponsorPannel="matExpansionPanel"
          id="far-view-sponsor-panel"
          expanded
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex justify-content-between">
              <span>Sponsor</span>
              <mat-icon svgIcon="badge-account-outline"></mat-icon
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider class="panel-header-divider"></mat-divider>
          <div class="content row">
            <div class="col">
              <ng-container *ngIf="far.sponsor as sponsor; else noSponsorData">
                <div class="d-flex align-item-center">
                  <div>
                    <mat-icon
                      [svgIcon]="'account-circle'"
                      class="mr-1"
                      matTooltip="Sponsor Name"
                    >
                      account-circle
                    </mat-icon>
                  </div>
                  <div
                    matTooltip="{{ sponsor.surname }}, {{ sponsor.givenName }}"
                  >
                    <div *ngIf="sponsor?.surname || sponsor?.givenName">
                      <span
                        *ngIf="sponsor?.surname"
                        style="line-break: anywhere"
                        >{{ sponsor?.surname }}, </span
                      ><span *ngIf="sponsor?.givenName">
                        {{ sponsor?.givenName | shorten: 20:"..." }}</span
                      >
                      <span *ngIf="!sponsor?.givenName && sponsor?.surname">
                        FNU</span
                      >
                    </div>
                    <div *ngIf="!sponsor?.surname && !sponsor?.givenName">
                      N/A
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-item-center"
                  style="line-break: anywhere"
                >
                  <div>
                    <mat-icon class="mr-1" matTooltip="Sponsor Email Address"
                      >email</mat-icon
                    >
                  </div>
                  <span [matTooltip]="sponsor.email || ''">
                    {{ sponsor.email | default }}
                  </span>
                </div>
                <div class="d-flex align-item-center">
                  <div>
                    <mat-icon class="mr-1" matTooltip="Sponsor Phone Number"
                      >smartphone</mat-icon
                    >
                  </div>
                  <span [matTooltip]="sponsor.phone || ''">
                    {{ sponsor.phone | default }}
                  </span>
                </div>
              </ng-container>
              <ng-template #noSponsorData>
                <div class="d-flex align-item-center">
                  <mat-icon
                    [svgIcon]="'account-circle'"
                    class="mr-1"
                    matTooltip="Sponsor Name"
                  >
                    account-circle
                  </mat-icon>
                  N/A
                </div>
                <div class="d-flex align-item-center">
                  <mat-icon class="mr-1" matTooltip="Sponsor Email Address"
                    >email</mat-icon
                  >
                  N/A
                </div>
                <div class="d-flex align-item-center">
                  <mat-icon class="mr-1" matTooltip="Sponsor Phone Number"
                    >smartphone</mat-icon
                  >
                  N/A
                </div>
              </ng-template>
            </div>
            <mat-divider
              [vertical]="true"
              class="panel-vertical-divider"
            ></mat-divider>
            <div class="col-2 d-flex justify-content-center">
              <button
                *ngIf="far.isEditable"
                mat-icon-button
                id="far-view-edit-sponsor"
                matTooltip="Edit Sponsor"
                [appSponsorEdit]="far"
                (sponsorEdited)="far.sponsor = $event"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <!--DESCRIPTION-->
        <mat-expansion-panel
          class="flex-fill mt-3"
          #descriptionPanel="matExpansionPanel"
          id="far-view-description-panel"
          expanded
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex justify-content-between">
              <span>Event Description</span>
              <mat-icon svgIcon="text-box-outline"></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider class="panel-header-divider"></mat-divider>
          <div class="content row">
            <div class="col description-body">
              {{ far.description | default }}
            </div>
            <mat-divider
              [vertical]="true"
              class="panel-vertical-divider"
            ></mat-divider>
            <div class="col-2 d-flex justify-content-center">
              <button
                *ngIf="far.isEditable"
                mat-icon-button
                (click)="onDescriptionEdit(far)"
                matTooltip="Edit Description"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <!--Attachments Button - No Existing Attachments-->
        <app-attachments
          #attachmentList
          (countChange)="attachmentsCount = $event"
          [editable]="far.isEditable"
          [deletable]="far.isDeletable"
          *ngIf="far?.id"
          parentType="FAR"
          [parentId]="far.id"
          [buttonStyle]="true"
        ></app-attachments>
        <!--Attachments Section - Attachments Exist-->
        <mat-expansion-panel
          class="flex-fill mt-3 mb-1"
          #attachmentsPanel="matExpansionPanel"
          id="far-view-attachment-panel"
          [expanded]="attachmentsCount > 0"
          *ngIf="attachmentsCount > 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex justify-content-between">
              <span>Attachments</span>
              <mat-icon svgIcon="paperclip"></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider class="panel-header-divider"></mat-divider>
          <div class="content attachment-body">
            <app-attachments
              #attachmentList
              (countChange)="attachmentsCount = $event"
              [editable]="far.isEditable"
              [deletable]="far.isDeletable"
              *ngIf="far?.id"
              parentType="FAR"
              [parentId]="far.id"
            ></app-attachments>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #spinner>
  <mat-spinner></mat-spinner>
</ng-template>
