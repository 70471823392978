import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FarLocation } from '@shared/models';
import { ItineraryControlObservable } from '../../services/itinerary-control-observable.service';
import { Subject, takeUntil } from 'rxjs';
import { AppConfigService } from '@app/shared/services';
@Component({
  selector: 'app-itinerary-control',
  templateUrl: './itinerary-control.component.html',
  styleUrls: ['./itinerary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ItineraryControlComponent,
      multi: true,
    },
  ],
})
export class ItineraryControlComponent
  extends BaseControlComponent<FarLocation>
  implements Validator, OnInit, OnDestroy
{
  @ViewChild('itineraryForm')
  itineraryForm: NgForm;
  @Input() locationRequired: boolean = true;
  @Input() owningFarId: string = '';
  private ngUnsubscribe = new Subject<void>();
  errorMessage: string = '';
  showFarChangeWarning: boolean = false;
  farScreeningEligibility: boolean = false;
  descriptionExisted: boolean = false;

  constructor(
    private itineraryControl: ItineraryControlObservable,
    private config: AppConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.itineraryControl.ItineraryControlObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      this.locationRequired = event;
    });
    this.descriptionExisted = false;
  }

  showWarning() {
    if (this.value.id && this.farScreeningEligibility)
      this.showFarChangeWarning = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get valid(): boolean {
    return this.itineraryForm?.valid ?? true;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return this.valid ? null : { itinerary: true };
  }

  isValidDates(itineraryForm: NgForm) {
    //Start Date not more than a year from current date check
    if (this.value && this.value.startDate) {
      let yearFromToday = new Date(Date.now());
      yearFromToday.setFullYear(yearFromToday.getFullYear() + 1);
      if (this.value.startDate > yearFromToday) {
        this.errorMessage = ERROR_MESSAGE.INVALID_START_DATE;
        if (itineraryForm && itineraryForm.form)
          itineraryForm.form.setErrors({ incorrect: true });
        return false;
      }
    }

    //End Date Before Start Date Check
    if (this.value && this.value.startDate && this.value.endDate) {
      if (new Date(this.value.startDate) > new Date(this.value.endDate)) {
        this.errorMessage = ERROR_MESSAGE.INVALID_RANGE;
        if (itineraryForm && itineraryForm.form)
          itineraryForm.form.setErrors({ incorrect: true });
        return false;
      }

      //Start Date and End Date not more than a year apart Check
      let futureDate = new Date(this.value.startDate);
      futureDate.setFullYear(futureDate.getFullYear() + 1);
      if (new Date(this.value.endDate) > futureDate) {
        this.errorMessage = ERROR_MESSAGE.MAX_EXCEEDED;
        if (itineraryForm && itineraryForm.form)
          itineraryForm.form.setErrors({ incorrect: true });
        return false;
      }
    }
    if (itineraryForm && itineraryForm.form) itineraryForm.form.setErrors(null);
    return true;
  }
}

export enum ERROR_MESSAGE {
  MAX_EXCEEDED = 'End Date cannot be more than one year from Start Date',
  INVALID_RANGE = 'End Date cannot preceed Start Date - Reselect Dates',
  INVALID_START_DATE = 'Start Date cannot be more than one year from current date',
}
