import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  FAR,
  FarLocation,
  FilterParams,
  ForeignVisitor,
  PageableCollection,
} from '@app/shared/models';
import { merge, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Crud, CrudService } from '@shared/services/crud.service';
import { HttpClient } from '@angular/common/http';
import { SortSelect } from '../components';
import { FarMetrics } from '../models/far-metrics.model';
import { without } from 'lodash';
import { FarLocationService } from '@shared/services/farlocation.service';
import { SponsorService } from '@shared/services/sponsor.service';
import { FarCommentService } from '@shared/services/comment.service';

export enum FarSortField {
  ORG = 'org',
  EVENT_TITLE = 'eventTitle',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  SPONSOR_SURNAME = 'sponsorSurname',
  VISITS = 'visits',
  ASSOC_FV_STATUS = 'status',
  PURPOSE_TYPE = 'purposeType',
}

export const FarSortFieldLabels: { [key in FarSortField]: string } = {
  [FarSortField.ORG]: 'Owner Org',
  [FarSortField.EVENT_TITLE]: 'Event Title',
  [FarSortField.CREATED_DATE]: 'Created Date',
  [FarSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
  [FarSortField.SPONSOR_SURNAME]: 'Sponsor Surname',
  [FarSortField.VISITS]: 'Visits',
  [FarSortField.ASSOC_FV_STATUS]: 'Foreign National State',
  [FarSortField.PURPOSE_TYPE]: 'Purpose Type',
};

export const FarSorts: SortSelect[] = without(
  Object.values(FarSortField),
  // Add non-sortable fields here
  FarSortField.ORG,
  FarSortField.VISITS,
  FarSortField.ASSOC_FV_STATUS
).map((value) => {
  return { label: FarSortFieldLabels[value], value };
});

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/fars`,
  hasIdPathUpdate: true,
  entity: 'Foreign Access Request',
})
export class FarService extends CrudService<FAR> {
  modelChanges$ = merge(
    this.changeEvent$,
    this.farLocationService.changeEvent$,
    this.sponsorService.changeEvent$,
    this.commentService.changeEvent$
  );

  constructor(
    http: HttpClient,
    private farLocationService: FarLocationService,
    private sponsorService: SponsorService,
    private commentService: FarCommentService
  ) {
    super(http);
  }

  /**
   * @param farId
   * @param model
   * @returns
   */
  public addFvToFar(farId: string, fvs: ForeignVisitor[]): Observable<FAR> {
    return this.http
      .put<FAR>(
        `${this.apiUrl}/${farId}/fvs/add`,
        { foreignVisitors: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: farId,
          })
        )
      );
  }

  public removeFvFromFar(
    farId: string,
    fvs: ForeignVisitor[]
  ): Observable<FAR> {
    return this.http
      .put(
        `${this.apiUrl}/${farId}/fvs/remove`,
        { foreignVisitors: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: farId,
          })
        )
      );
  }

  public find(
    filterParams?: FilterParams
  ): Observable<PageableCollection<FAR>> {
    return this.http.get<PageableCollection<FAR>>(`${this.apiUrl}`, {
      params: filterParams?.httpParams,
    });
  }

  getFarMetrics(): Observable<FarMetrics> {
    return this.http.get<FarMetrics>(`${this.apiUrl}/metrics`);
  }

  compareObjs(objA: any, objB: any): number {
    return objA.toUpperCase().localeCompare(objB.toUpperCase());
  }
}
