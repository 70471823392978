import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { environment } from '@environments/environment';
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from 'ng2-file-upload';
import {
  AlertService,
  AppConfigService,
  TemplateService,
  UserService,
} from '@app/shared/services';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { ClassificationModel } from '@shared/models/classification.model';
import { SearchLocationsDialogComponent } from '../search-locations-dialog/search-locations-dialog.component';
import { Organization, Role } from '@shared/models';
import { NavigationExtras, Params, Router } from '@angular/router';

export type BulkUploadType = 'FarFV' | 'FV' | 'Org' | 'Screening';

export interface BulkTemplate {
  label: string;
  file: string;
  type: string;
}

export interface BulkSetting {
  label: string;
  templates: BulkTemplate[] | null;
  path: string;
  submitButtonLabel: string;
  errorTableColumns: any[];
  adminOwnerOrgSelect: boolean;
  showCountrySelect: boolean;
}

export const BulkUploadSettings: { [key in BulkUploadType]: BulkSetting } = {
  FV: {
    label: 'Foreign National Bulk Upload',
    templates: [
      {
        label: 'FN Template',
        file: `ForeignVisitor-Template.xlsx`,
        type: 'foreignVisitor',
      },
    ],
    path: `${environment.apiUrl}/fvs/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: true,
    showCountrySelect: true,
  },
  FarFV: {
    label: 'Foreign National Bulk Upload to FAR',
    templates: [
      {
        label: 'FN Template',
        file: `ForeignVisitor-Template.xlsx`,
        type: 'foreignVisitor',
      },
    ],
    path: `${environment.apiUrl}/fvs/far/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: false,
    showCountrySelect: true,
  },
  Org: {
    label: 'Location Bulk Upload',
    templates: [
      {
        label: 'Location Template',
        file: 'OrgLocTemplate.xlsx',
        type: 'orgLocation',
      },
    ],
    path: `${environment.apiUrl}/org/upload`,
    submitButtonLabel: 'Upload',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: true,
    showCountrySelect: true,
  },
  Screening: {
    label: 'Screening Results Import',
    templates: null,
    path: `${environment.apiUrl}/screeningDecision/upload`,
    submitButtonLabel: 'Import',
    errorTableColumns: ['row', 'messages'],
    adminOwnerOrgSelect: false,
    showCountrySelect: false,
  },
};

export interface BulkUploadData {
  type: BulkUploadType;
  farId: any[];
}

@Directive({
  selector: '[appBulkUpload]',
})
export class BulkUploadDirective {
  @Input() appBulkUpload?: BulkUploadType;
  @Input() farId: any[] = [];

  @Output() appBulkUploaded = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appBulkUpload) return;
    const dialog = BulkUploadComponent.openDialog(this.dialog, {
      type: this.appBulkUpload,
      farId: this.farId,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) this.appBulkUploaded.emit(result);
    });
  }
}

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent {
  @ViewChild('bulkUploadDirective') bulkUploadDirective: BulkUploadDirective;
  Role = Role;

  inputDisplayCtrl = new UntypedFormControl();

  @ViewChild('fileInput') fileInput: ElementRef;

  settings: BulkSetting;
  farId: any[] = [];
  uploader: FileUploader;
  showErrors: boolean = false;
  countrySearch: boolean = false;
  errMsg: any = {};
  errorsToDisplay: any = [];

  classificationModel: ClassificationModel;
  owningOrg: Organization;
  _defaultOptions: FileUploaderOptions;
  farScreeningEligibility: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<BulkUploadComponent>,
    public locationsDialogRef: MatDialogRef<SearchLocationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkUploadData,
    public resultsDialog: MatDialog,
    private auth: AuthService,
    private userService: UserService,
    private alert: AlertService,
    private template: TemplateService,
    private config: AppConfigService,
    private router: Router
  ) {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.settings = BulkUploadSettings[this.data.type];
    if (this.farScreeningEligibility && this.data.type === 'FV') {
      this.settings.path = BulkUploadSettings['FarFV'].path;
    }
    this.farId = this.data.farId;
    if (this.settings) {
      this._defaultOptions = {
        itemAlias: 'file',
        method: 'POST',
        url: this.settings.path,
      };
      this.uploader = new FileUploader(this._defaultOptions);

      this.owningOrg = this.auth.getOrganization() as Organization;

      this.uploader.onAfterAddingFile = this.onAddFile.bind(this);
      this.uploader.onErrorItem = this.onError.bind(this);
      this.uploader.onSuccessItem = this.onSuccess.bind(this);
    }
  }

  onAddFile(_item: FileItem) {
    if (this.uploader.queue.length > 1) {
      this.uploader.queue.splice(0, 1);
    }
    this.inputDisplayCtrl.reset();
    this.showErrors = false;
    this.errorsToDisplay = [];
  }

  clearFiles() {
    this.uploader.clearQueue();
    this.fileInput.nativeElement.value = '';
  }

  hasOrg() {
    return this.owningOrg != null;
  }

  generateFnTooltip() {
    if (!this.farScreeningEligibility)
      return "Foreign Nationals - Fields Required for Screening: Given Name, Surname, Date of Birth, Passport Number and Passport Country. For Single Name FNs, enter the single name into the Surname field and 'FNU' into the Given Name field";
    else {
      return "Foreign Nationals - Fields Required for Screening: Given Name, Surname, Date of Birth, Passport Number, Passport Country, and an Active FAR. For Single Name FNs, enter the single name into the Surname field and 'FNU' into the Given Name field";
    }
  }

  onError(
    _item: FileItem,
    response: string,
    status: number,
    _headers: ParsedResponseHeaders
  ) {
    this.clearFiles();
    if (status === 0) {
      // infer that browser detected a change in the file.
      this.inputDisplayCtrl.setErrors({ error: 'File Changed' });
      this.errMsg.message = 'File Changed. Select a new file.';
      return;
    }

    this.errMsg = JSON.parse(response);
    this.inputDisplayCtrl.setErrors({ error: response }, { emitEvent: true });
    if (this.errMsg.validationErrors?.length > 0) {
      this.errorsToDisplay = this.errMsg.validationErrors;
    } else if (this.errMsg.duplicateMessages?.length > 0) {
      this.errorsToDisplay = this.errMsg.duplicateMessages;
    }
  }

  /**
   * Presents a snackbar to the user when the API returns on successful upload
   * then navigates to ineligible FVs.
   *
   * @param _item File being uploaded
   * @param response message and info coming back from the API
   */
  onSuccess(_item: FileItem, response: any) {
    let res = JSON.parse(response);
    const hasIneligibleMessage: boolean =
      res.informationMessages &&
      res.informationMessages['screening ineligible'];
    this.alert.successAlert(this.buildSnackBar(res));
    this.dialogRef.close(true);
    let navExtras: NavigationExtras = {
      queryParams: {
        sortBy: 'createdDate',
        direction: 'DESC',
      },
    };

    if (hasIneligibleMessage) {
      Object.assign(navExtras.queryParams as Params, {
        screeningEligible: false,
        screeningIneligible: true,
      });
    }
  }

  /**
   * @param res Response from API
   * @returns a string to display inside a snackbar
   */
  buildSnackBar(res: any): string {
    let message: string;
    const ineligObj =
      res?.informationMessages?.['screening ineligible']?.['messages'] ?? {};

    const ineligMap = new Map(
      Object.keys(ineligObj).map((key) => [key, ineligObj[key]])
    );
    let ineligibleCount: number = ineligMap.size;

    if (!ineligibleCount) return res.message;

    let successMessage = res.message + '.  ';
    if (ineligibleCount > 1) {
      successMessage += ineligibleCount + ' FNs are ineligible for screening.';
    } else {
      successMessage += 'One FN is ineligible for screening.';
    }
    message = successMessage;
    return message;
  }

  isFarId() {
    if (!this.farScreeningEligibility) return true;
    if (this.data.type === 'FV' && this.farId.length === 0) return false;
    return true;
  }

  async submit() {
    if (!this.uploader.queue?.length) return;
    const newOptions = { ...this._defaultOptions };
    const additionalParameter: FileUploaderOptions['additionalParameter'] = {};

    if (
      this.classificationModel?.classification?.length &&
      this.classificationModel?.classificationXML?.length
    ) {
      additionalParameter.classification =
        this.classificationModel?.classification;
      additionalParameter.classificationXml =
        this.classificationModel?.classificationXML;
    }

    let skipOwningOrg = false;
    if (this.farId && this.farId.length > 0) {
      if (this.farId[0].id) {
        let idArray = [];
        for (let far of this.farId) {
          idArray.push(far.id);
        }
        additionalParameter.farIds = idArray;
      } else additionalParameter.farIds = this.farId;
      skipOwningOrg = true;
    }

    if (this.auth.isAdmin && this.owningOrg && !skipOwningOrg) {
      additionalParameter.owningOrgId = this.owningOrg.id;
    }

    this.showErrors = false;
    this.errorsToDisplay = [];
    const token = await this.auth.getToken();
    this.errMsg = {};

    if (this.userService.filteredRoles?.length) {
      newOptions.headers = [
        { name: 'Role', value: this.userService.filteredRoles.join(',') },
      ];
    }

    newOptions.additionalParameter = additionalParameter;
    newOptions.authToken = token;
    this.uploader.setOptions(newOptions);

    this.uploader.queue[0].upload();
  }

  static openDialog(
    dialog: MatDialog,
    data: BulkUploadData
  ): MatDialogRef<BulkUploadComponent> {
    return dialog.open<BulkUploadComponent, BulkUploadData>(
      BulkUploadComponent,
      {
        data,
        minWidth: '800px',
      }
    );
  }

  hideCountrySearch() {
    this.countrySearch = !this.countrySearch;
  }

  download(tpl: BulkTemplate) {
    this.template.download(tpl.type, tpl.file).subscribe();
  }
}
