<mat-card class="far-card" matRipple>
  <app-far-header [far]="far"></app-far-header>
  <app-far-event [primaryLocation]="far?.primaryFar"></app-far-event>
  <app-far-fvs-rollup
    *ngIf="showRollups"
    [foreignAccessRequestScreeningCounts]="
      far?.foreignAccessRequestScreeningCounts
    "
  ></app-far-fvs-rollup>
  <app-far-metadata [far]="far"></app-far-metadata>
</mat-card>
